import { useState, useEffect } from "react"
import blue_chips from "../../images/chips.png"
import tan_chips from "../../images/chips_tan.png"
import artic_chips from "../../images/chips_artic.png"

export const Builder = () => {

  const [screenWidth, setScreenWidth] = useState<number>(0)

  const [blueChipsMoved, setBlueChipsMoved] = useState<boolean>(false)
  const [blueChipsResized, setBlueChipsResized] = useState<boolean>(false)
  const [grayFloorMoved, setGrayFloorMoved] = useState<boolean>(false)
  const [grayFloorResized, setGrayFloorResized] = useState<boolean>(false)

  const [tanChipsMoved, setTanChipsMoved] = useState<boolean>(false)
  const [tanChipsResized, setTanChipsResized] = useState<boolean>(false)
  const [tanFloorMoved, setTanFloorMoved] = useState<boolean>(false)
  const [tanFloorResized, setTanFloorResized] = useState<boolean>(false)

  const [articChipsMoved, setArticChipsMoved] = useState<boolean>(false)
  const [articChipsResized, setArticChipsResized] = useState<boolean>(false)
  const [articFloorMoved, setArticFloorMoved] = useState<boolean>(false)
  const [articFloorResized, setArticFloorResized] = useState<boolean>(false)

  useEffect(() => {
    updateDimensions()
    
    window.addEventListener("resize", updateDimensions)
  
    return () => window.removeEventListener("resize", updateDimensions)
    
  }, [])
  
  const updateDimensions = () => {
    setScreenWidth(window.innerWidth)
  }

  const handleBlueChipsMove = () => {
    if(tanChipsMoved) {
      setTanChipsMoved(false)
      setTanChipsResized(false)
    }
    if(articChipsMoved) {
      setArticChipsMoved(false)
      setArticChipsResized(false)
    }
    setBlueChipsResized(true)
    setTimeout(() => setBlueChipsMoved(true), 200)
  }

  const handleGrayFloorMove = () => {
    if(tanFloorMoved) {
      setTanFloorMoved(false)
      setTanFloorResized(false)
    }
    if(articFloorMoved) {
      setArticFloorMoved(false)
      setArticFloorResized(false)
    }
    setGrayFloorResized(true)
    setTimeout(() => setGrayFloorMoved(true), 200)
  }

  const handleTanChipsMove = () => {
    if(articChipsMoved) {
      setArticChipsMoved(false)
      setArticChipsResized(false)
    }
    if(blueChipsMoved) {
      setBlueChipsMoved(false)
      setBlueChipsResized(false)
    }
    setTanChipsResized(true)
    setTimeout(() => setTanChipsMoved(true), 200)
  }

  const handleTanFloorMove = () => {
    if(grayFloorMoved) {
      setGrayFloorMoved(false)
      setGrayFloorResized(false)
    }
    if(articFloorMoved) {
      setArticFloorMoved(false)
      setArticFloorResized(false)
    }
    setTanFloorResized(true)
    setTimeout(() => setTanFloorMoved(true), 200)
  }

  const handleArticChipsMove = () => {
    if(tanChipsMoved) {
      setTanChipsMoved(false)
      setTanChipsResized(false)
    }
    if(blueChipsMoved) {
      setBlueChipsMoved(false)
      setBlueChipsResized(false)
    }
    setArticChipsResized(true)
    setTimeout(() => setArticChipsMoved(true), 200)
  }

  const handleArticFloorMove = () => {
    if(tanFloorMoved) {
      setTanFloorMoved(false)
      setTanFloorResized(false)
    }
    if(grayFloorMoved) {
      setGrayFloorMoved(false)
      setGrayFloorResized(false)
    }
    setArticFloorResized(true)
    setTimeout(() => setArticFloorMoved(true), 200)
  }

  const resetChips = () => {
    setBlueChipsMoved(false)
    setBlueChipsResized(false)
    setGrayFloorMoved(false)
    setGrayFloorResized(false)
    setTanChipsMoved(false)
    setTanChipsResized(false)
    setTanFloorMoved(false)
    setTanFloorResized(false)
    setArticChipsMoved(false)
    setArticChipsResized(false)
    setArticFloorMoved(false)
    setArticFloorResized(false)
  }

  return (
    <div className="w-screen h-screen relative">
      <div className="absolute h-[100%] left-[0%] lg:left-[15%] 2xl:left-[25%] w-[100%] lg:w-[70%] 2xl:w-[50%] bg-slate-100" id="shadow">
        <div className="w-[100%] h-[100%] relative flex flex-col items-center">
          <h1 className="text-[6vw] lg:text-[4vw] xl:text-[2.5vw] mt-[20%] md:mt-[10%] xl:mt-[5%] font-bodoni font-semibold">Design your own custom epoxy floor</h1>
          {/* {
            screenWidth > 768 ?
            <> */}
              <div className="absolute top-[30%] h-[30%] xl:h-[40%] left-[30%] w-[40%] border-2 border-black bg-white rounded-xl">

              </div>
              <img
                className={`z-30 absolute ${blueChipsMoved ? "translate-y-[100%] xl:translate-y-[75%]" : ""} ${blueChipsResized ? "h-[30%] xl:h-[40%] w-[40%]" : "top-[25%] right-[5%] w-[15%] h-[10%] xl:h-[15%]"} border-2 border-black transition-all duration-200 ease-linear ${!blueChipsMoved && "hover:scale-125 cursor-pointer"} rounded-xl`}
                onClick={() => (!blueChipsMoved || !blueChipsResized) && handleBlueChipsMove()}
                src={blue_chips}
                alt="chips"
              />
              <div
                className={`z-20 bg-[#969fa6] absolute ${grayFloorMoved ? "translate-y-[100%] xl:translate-y-[75%]" : ""} ${grayFloorResized ? "h-[30%] xl:h-[40%] w-[40%]" : "top-[25%] left-[5%] w-[15%] h-[10%] xl:h-[15%]"} border-2 border-black transition-all duration-200 ease-linear ${!grayFloorMoved && "hover:scale-125 cursor-pointer"} rounded-xl`}
                onClick={() => (!grayFloorMoved || !grayFloorResized) && handleGrayFloorMove()}
              >
              </div>
              <img
                className={`z-30 absolute ${tanChipsMoved ? "translate-y-[100%] xl:translate-y-[75%]" : ""} ${tanChipsResized ? "h-[30%] xl:h-[40%] w-[40%]" : "top-[45%] right-[5%] w-[15%] h-[10%] xl:h-[15%]"} border-2 border-black transition-all duration-200 ease-linear ${!tanChipsMoved && "hover:scale-125 cursor-pointer"} rounded-xl`}
                onClick={() => (!tanChipsMoved || !tanChipsResized) && handleTanChipsMove()}
                src={tan_chips}
                alt="chips"
              />
              <div
                className={`z-20 bg-[#cbb18e] absolute ${tanFloorMoved ? "translate-y-[100%] xl:translate-y-[75%]" : ""} ${tanFloorResized ? "h-[30%] xl:h-[40%] w-[40%]" : "top-[45%] left-[5%] w-[15%] h-[10%] xl:h-[15%]"} border-2 border-black transition-all duration-200 ease-linear ${!tanFloorMoved && "hover:scale-125 cursor-pointer"} rounded-xl`}
                onClick={() => (!tanFloorMoved || !tanFloorResized) && handleTanFloorMove()}
              >
              </div>
              <img
                className={`z-30 absolute ${articChipsMoved ? "translate-y-[100%] xl:translate-y-[75%]" : ""} ${articChipsResized ? "h-[30%] xl:h-[40%] w-[40%]" : "top-[65%] right-[5%] w-[15%] h-[10%] xl:h-[15%]"} border-2 border-black transition-all duration-200 ease-linear ${!articChipsMoved && "hover:scale-125 cursor-pointer"} rounded-xl`}
                onClick={() => (!articChipsMoved || !articChipsResized) && handleArticChipsMove()}
                src={artic_chips}
                alt="chips"
              />
              <div
                className={`z-20 bg-[#c9d2d1] absolute ${articFloorMoved ? "translate-y-[100%] xl:translate-y-[75%]" : ""} ${articFloorResized ? "h-[30%] xl:h-[40%] w-[40%]" : "top-[65%] left-[5%] w-[15%] h-[10%] xl:h-[15%]"} border-2 border-black transition-all duration-200 ease-linear ${!articFloorMoved && "hover:scale-125 cursor-pointer"} rounded-xl`}
                onClick={() => (!articFloorMoved || !articFloorResized) && handleArticFloorMove()}
              >
              </div>
              <div
                className="absolute bottom-[10%] xl:bottom-[7.5%] h-[8%] w-[40%] xl:w-[20%] left-[30%] xl:left-[40%] text-white bg-[#2d3bc0] text-[4.5vw] lg:text-[3vw] xl:text-[1.5vw] border-2 border-black flex items-center justify-center cursor-pointer hover:scale-125 rounded-xl"
                onClick={() => resetChips()}
              >
                reset
              </div>
        </div>
      </div>
    </div>
  )
}