import bigFloor from "../../images/bigfloorcrop.png"
import logo from "../../images/logo2.png"
import arrow from "../../images/arrow.png"

export const Landing = () => {

  return (
    <div className="w-screen h-screen relative">
      <div className="absolute h-[100%] left-[0%] lg:left-[15%] 2xl:left-[25%] w-[100%] lg:w-[70%] 2xl:w-[50%]" id="shadow">
        <div className="w-[100%] h-[100%] relative flex flex-col items-center">
          <div className="h-[15%] w-[100%] flex flex-row items-center border-b-4 border-[#2d3bc0] bg-slate-100">
            <img className="w-[30%] mt-[1%] lg:mt-0" src={logo} alt="logo" />
            <h1 className="text-[5vw] sm:text-[4.5vw] lg:text-[3.5vw] 2xl:text-[2vw] font-semibold">Reliable Epoxy Floors</h1>
          </div>
          <img className="h-[70%] w-[100%] opacity-[95%]" src={bigFloor} alt="bigfloor" id="shadow" />
          <img className="mt-[10%] sm:mt-[7.5%] lg:mt-[5%] w-[10%] sm:w-[8%] lg:w-[6%] animate-bounce" src={arrow} alt="arrow" />
          <h1 className="px-2 absolute top-[30%] text-center text-[6vw] sm:text-[4vw] lg:text-[2.5vw] 2xl:text-[1.75vw] font-bodoni bg-slate-100 rounded-none sm:rounded-xl font-semibold">Improving garage floors in the Carolinas for 20 years</h1>
        </div>
      </div>
    </div>
  )
}