import {
  Landing,
  About,
  Builder,
  BeforeAndAfter,
  Contact,
  Footer
} from "./components"

export default function App() {

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      <Landing />
      <About />
      <Builder />
      <BeforeAndAfter />
      <Contact />
      <Footer />
    </div>
  )
}