import logo from "../../images/logo2.png"

export const Footer = () => {

  return(
    <div className="w-screen h-[25vh] sm:h-[50vh] relative">
      <div className="absolute h-[100%] left-[0%] lg:left-[15%] 2xl:left-[25%] w-[100%] lg:w-[70%] 2xl:w-[50%]" id="shadow">
        <div className="w-[100%] h-[100%] flex flex-col justify-center items-center bg-slate-100 font-medium text-[5vw] sm:text-[4vw] xl:text-[3vw] 2xl:text-[1.5vw] border-t-4 border-[#2d3bc0]">
          <span>Reliable Epoxy Floors</span>
          <span>Newport, NC</span>
          <img className="w-[30%] h-[30%]" src={logo} alt="logo" />
        </div>
      </div>
    </div>
  )
}