import beforeDoor from "../../images/before_door.jpg"
import beforeFloor from "../../images/before_floor.jpg"
import afterDoor from "../../images/after_door.jpg"
import afterFloor from "../../images/after_floor.jpg"
import arrow from "../../images/arrow.png"
import bigDoor from "../../images/finished_garagedoor.jpg"

export const BeforeAndAfter = () => {

  return (
    <div className="w-screen min-h-[100vh] relative">
      <div className="absolute h-[100%] left-[0%] lg:left-[15%] 2xl:left-[25%] w-[100%] lg:w-[70%] 2xl:w-[50%] border-t-4 border-b-4 border-[#2d3bc0]" id="shadow">
        <div className="relative w-[100%] h-[100%] flex flex-col items-center justify-between">
          <img className="absolute z-10 w-[100%] h-[100%] opacity-10" src={bigDoor} alt="floor" />
          <h1 className="z-20 text-[6vw] lg:text-[4vw] xl:text-[2.5vw] font-bodoni font-semibold text-center">Before & After</h1>
          <div className="z-20 flex flex-col justify-around items-center h-full w-[90%] sm:w-[70%]">
            <div className="flex flex-row h-[50%] w-[100%] justify-around items-center">
              <img className="border-2 border-black rounded-xl h-[60%] sm:h-[80%] w-[45%] sm:w-[30%] tall:w-[40%]" src={beforeDoor} alt="before" id="shadow" />
              <img className="w-[7.5%] rotate-[270deg]" src={arrow} alt="arrow" />
              <img className="border-2 border-black rounded-xl h-[60%] sm:h-[80%] w-[45%] sm:w-[30%] tall:w-[40%]" src={afterDoor} alt="after" id="shadow" />
            </div>
            <div className="flex flex-row h-[50%] w-[100%] justify-around items-center">
              <img className="border-2 border-black rounded-xl h-[60%] sm:h-[80%] w-[45%] sm:w-[30%] tall:w-[40%]" src={beforeFloor} alt="before" id="shadow" />
              <img className="w-[7.5%] rotate-[270deg]" src={arrow} alt="arrow" />
              <img className="border-2 border-black rounded-xl h-[60%] sm:h-[80%] w-[45%] sm:w-[30%] tall:w-[40%]" src={afterFloor} alt="after" id="shadow" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}