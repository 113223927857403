import bigFloor from "../../images/finished_floor.jpg"

export const About = () => {

  return (
    <div className="w-screen h-[30vh] sm:h-[40vh] xl:h-[50vh] relative">
      <div className="absolute h-[100%] left-[0%] lg:left-[15%] 2xl:left-[25%] w-[100%] lg:w-[70%] 2xl:w-[50%] border-t-4 border-b-4 border-[#2d3bc0]" id="shadow">
        <div className="relative w-[100%] h-[100%] flex flex-col items-center px-[2%]">
          <img className="absolute z-10 w-[100%] h-[100%] opacity-25" src={bigFloor} alt="floor" />
          <h1 className="font-bodoni z-20 font-semibold text-[7.5vw] sm:text-[5vw] lg:text-[2.5vw] my-[5%]">About Us</h1>
          <p className="text-center z-20 font-bodoni font-bold text-[3.5vw] sm:text-[2.5vw] lg:text-[1.75vw] xl:text-[1.2vw]">Epoxy floors are typically one third the cost of a Polyurea flooring application. We clean - etch - and apply the Epoxy with chips in a single day. We service all of Eastern North Carolina and have 20 years of experience. We look forward to transforming your garage floor from unsightly to unbelievable!</p>
        </div>
      </div>
    </div>
  )
}